import * as network from '../HostedFields/network';

const detectSpoof = (navigatorData, deviceData) => {
  const expectedPlatforms = ['Win32', 'Win64', 'MacIntel', 'Linux x86_64', 'Linux armv81', 'iPhone', 'Linux aarch64'];
  const expectedVendors = ['Google Inc.', 'Apple Computer, Inc.', 'Mozilla Foundation'];

  if (navigatorData.driver !== false) {
    // console.log(`Detected a headless browser! Navigator Data: ${JSON.stringify(navigatorData)} Device Data: ${JSON.stringify(deviceData)}`)
    network.sendSOS(`Detected a headless browser! Navigator Data: ${JSON.stringify(navigatorData)} Device Data: ${JSON.stringify(deviceData)}`);
  }

  if (!expectedPlatforms.includes(navigatorData.platform)) {
    // console.log(`Detected spoof. Navigator platform is not what is expected... Navigator Data: ${JSON.stringify(navigatorData)} Device Data: ${JSON.stringify(deviceData)}`)
    network.sendSOS(`Detected spoof. Navigator platform is not what is expected... Navigator Data: ${JSON.stringify(navigatorData)} Device Data: ${JSON.stringify(deviceData)}`);
  }

  if (!expectedVendors.includes(navigatorData.vendor)) {
    // console.log(`Detected spoof. Navigator vendor is not what is expected... Navigator Data: ${JSON.stringify(navigatorData)} Device Data: ${JSON.stringify(deviceData)}`)
    network.sendSOS(`Detected spoof. Navigator vendor is not what is expected... Navigator Data: ${JSON.stringify(navigatorData)} Device Data: ${JSON.stringify(deviceData)}`);
  }
};


const getNavigatorData = () => {
  return {
    appCodeName: window.navigator.appCodeName,
    appName: window.navigator.appName,
    appVersion: window.navigator.appVersion,
    cookieEnabled: window.navigator.cookieEnabled,
    cpuClass: window.navigator.cpuClass,
    geolocation: window.navigator.geolocation,
    language: window.navigator.language,
    onLine: window.navigator.onLine,
    oscpu: window.navigator.oscpu,
    platform: window.navigator.platform,
    product: window.navigator.product,
    productSub: window.navigator.productSub,
    vendor: window.navigator.vendor,
    userAgent: window.navigator.userAgent,
    driver: window.navigator.webdriver
  };
};

const getDeviceData = () => {
  return { 
    deviceMemory: window.navigator.deviceMemory,
    hardwareConcurrency: window.navigator.hardwareConcurrency,
    screen: {
      height: window.screen.height,
      width: window.screen.width,
      colorDepth: window.screen.colorDepth,
      pixelDepth: window.screen.pixelDepth
    }
  };
};



export const inspectBrowser = async() => {
  let deviceData = "unavailable"
  let navigatorData = "unavailable"
  try {
    navigatorData = getNavigatorData();
    deviceData = getDeviceData();
    // console.log(`Navigator Data: ${JSON.stringify(navigatorData)}`)
    // console.log(`Device Data: ${JSON.stringify(deviceData)}`)
    detectSpoof(navigatorData, deviceData)
  }
  catch {}
  return {
    deviceData,
    navigatorData
  };
};
